@use "./config/" as *;
@forward "./main-scss/";
@forward "./details/common";
@forward "./listing/common";
@forward "./listing/related";
.waf-detail {
  position: relative;
  padding-top: calc(75% + var(--space-5));
  padding-inline: 1rem;
  .article-featured-media {
    @extend %pos-tl;
    @extend %w-100;
  }
  .img-box {
    border-radius: 0;
  }
  section {
    .img-box {
      padding-bottom: 0;
    }
  }
}
@include mq(col-md) {
  .waf-detail {
    padding-top: var(--space-8);
    padding-inline: var(--space-4);
    .article-featured-media {
      position: relative;
      margin-bottom: var(--space-6);
    }
    .img-box {
      border-radius: var(--full-radius);
    }
  }
}
@include mq(col-xl) {
  .waf-detail {
    max-width: 83.88rem;
    margin-inline: auto;
  }
}